import React from "react"
import "./Camp2024Summer.css";
import {JodanConfiguration} from "../../data/JodanConfiguration";

function Camp2024Summer() {
    return (
        <section className="container camp2024summer-section">
            <div className="row">
                <div className="col12">
                    <h1>Lato 2024</h1>
                    Proszę wysyłać zgłoszenia na adres: <a href="mailto:{JodanConfiguration.contactCampEmail}" target="_blank" rel="noreferrer">{JodanConfiguration.contactCampEmail}</a>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <div className="camp-box">
                        <h2>22-30 czerwca</h2>
                        <h3>AIKIDO i BJJ</h3>
                        Ośrodek wypoczynkowy Bajka.<br/><br/>
                        <a href="https://www.facebook.com/events/s/oboz-bajka/915052833685700/" target="_blank" rel="noreferrer">wydarzenie na FB >></a>
                        {/*<a href="http://turnusy.owbajka.pl" target="_blank" rel="noreferrer">bajka >></a>*/}
                    </div>
                </div>
                <div className="col-12 col-sm-4">
                    <div className="camp-box">
                        <h2>8-17 lipca</h2>
                        <h3>AIKIDO</h3>
                        Ośrodek wypoczynkowy Kormoran.<br/><br/>
                        <a href="https://www.facebook.com/events/s/aikido-camp-kormoran-2024/1456049301652478/" target="_blank" rel="noreferrer">wydarzenie na FB >></a>
                        {/*<a href="https://owkormoran.com" target="_blank" rel="noreferrer">kormoran >></a>*/}
                    </div>
                </div>
                <div className="col-12 col-sm-4">
                    <div className="camp-box">
                        <h2>17-27 lipca</h2>
                        <h3>Safe Kid / Krav Maga</h3>
                        Ośrodek wypoczynkowy Kormoran.<br/><br/>
                        <a href="https://www.facebook.com/events/s/summer-camp-safe-kid-safe-teen/947236620256744/" target="_blank" rel="noreferrer">wydarzenie na FB >></a>
                        {/*<a href="https://owkormoran.com" target="_blank" rel="noreferrer">kormoran >></a>*/}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-4">
                    <div className="camp-box">
                        <h2>19-28 sierpnia</h2>
                        <h3>AIKIDO</h3>
                        Góry - Nawojowa.<br/><br/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Camp2024Summer;