import React from "react"
import "./FirstClassSection.css"

function FirstClassSection() {
    return(
        <section className="container first-class-section">
            <div className="row">
                <div className="col">
                    <h1>Pierwszy trening</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-8">
                    <div>
                        <h3>Jak mam się przygotować do pierwszego treningu?</h3>
                    </div>
                    <div>
                        <ul>
                            <li>zabierz ze sobą wygodny strój sportowy - krótkie spodenki, koszulka</li>
                            <li>w zależności od podłoża - możesz trenować w butach sportowych, boso lub specjalnych butach zapaśniczych</li>
                            <li>weź ze sobą wodę, napój lub izotonik</li>
                            <li>oraz ręcznik</li>
                            <li>zjedź coś - ale najpóźniej dwie godziny przed treningiem</li>
                            <li>wydrukuj i wypełnij <a href="/docs/jodan.kravmaga.deklaracja.pdf" download="Jodan Deklaracja" target="_blank" rel="noreferrer">oświadczenie</a></li>
                            <li>nie możesz wydrukować - nie szkodzi - wypełnisz na miejscu</li>
                        </ul>
                        <br/>
                    </div>
                    <div>
                        Pamiętaj, że to zajęcia kontaktowe także wszyscy dbamy o higienę oraz czyste stroje. Na zajęcia zdejmujemy także biżuterię.<br/><br/>
                    </div>
                    <div>
                        A w co zaopatrzyć się, gdy chcesz ćwiczyć regularnie? Zobacz <a href="/sprzet">tutaj</a>.
                    </div>
                </div>
                <div className="col-12 col-sm-4 align-items-center align-content-center picture-gray pt-3 text-center">
                    <img className="img-fluid rounded border-3" src="/components/kravka/first-class-section/pexels-allan-mas-5384399-400.jpg" alt="" />
                </div>
            </div>
        </section>
    )}
export default FirstClassSection;