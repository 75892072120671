import React from "react"
import "./CoursesSection.css"
import {Link} from "react-router-dom";

function CoursesSection() {
    return(
        <section className="container courses-section">
            <div className="row text-center">
                <div className="col-12 col-sm-4">
                    <Link className="btn btn-dark" to="/aikido" type="button">
                        <h2>Aikido</h2>
                        Zajęcia dla dzieci i młodzieży.
                    </Link>
                </div>
                <div className="col-12 col-sm-4">
                    <Link className="btn btn-dark" to="/kravka" type="button">
                        <h2>Krav Maga</h2>
                        Zajęcia dla młodzieży i dorosłych.
                    </Link>
                </div>
                <div className="col-12 col-sm-4">
                    <Link className="btn btn-dark" to="/obozy" type="button">
                        <h2>Obozy i wyjazdy</h2>
                        Dla wszystkich.
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default CoursesSection;