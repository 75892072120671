import React from "react"
import "./TopBar.css"
function TopBar(props) {
    return(
        <section className={`top-bar w-100 top-bar-${props.background}`}>
            <div className="row d-none d-sm-flex">
                <div className="col-sm text-sm-end text-center top-bar-trainer">
                    <img src="/components/top-bar/pawel-piotrowski-suit-2.png" alt=""/>
                </div>
                <div className="col-sm text-center text-sm-start d-sm-flex align-items-center">
                    <div className="krav-maga-font display-3 top-bar-logo">
                        {props.title}
                    </div>
                </div>
            </div>
            <div className="row d-sm-none">
                <div className="col-sm text-end text-sm-end d-sm-flex align-items-center">
                    <div className="krav-maga-font display-3 top-bar-logo">
                        {props.title}
                    </div>
                </div>
                <div className="col-sm text-sm-end text-center top-bar-trainer">
                    <img src="/components/top-bar/pawel-piotrowski-suit-2.png" alt=""/>
                </div>
            </div>
        </section>
    )
}

TopBar.defaultProps = {
    title: "Jodan",
    background: "main"
}
export default TopBar;