import React from "react"
import "./InstructorSection.css"
import InstructorPawel from "../instructor/InstructorPawel";
import InstructorMarcin from "./instructor/InstructorMarcin";
import InstructorZbigniew from "./instructor/InstructorZbigniew";
function InstructorSection() {

    let count = 1;

    function position() {
        return count++ % 2 === 1 ? "left" : "right";
    }

    return(
        <section className="container instructor-section">
            <div className="row">
                <div className="col">
                    <h1>Instruktorzy</h1>
                </div>
            </div>
            <hr/>
                <InstructorPawel position={position()}/>
            <hr/>
                <InstructorMarcin position={position()}/>
            <hr/>
                <InstructorZbigniew position={position()}/>
            <hr/>
        </section>
    )
}
export default InstructorSection;