// import "./styles.css"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import AboutJodanSection from "../components/home/AboutJodanSection";
import CoursesSection from "../components/home/CoursesSection";
import InstructorsSection from "../components/home/InstructorsSection";

function Home() {
    return (
        <>
            <Navbar title="Jodan" />
            <TopBar title="Jodan"/>
            <AboutJodanSection />
            <section className="section-wrapper-background">
                <CoursesSection />
            </section>
            <InstructorsSection />
        </>
    );
}

export default Home;