import Instructor from "../../../controls/Instructor";

function InstructorGabriela({position}) {
    let imageSrc = "/img/instructor/instructor-gabriela-mandla-tucholska.jpg";
    let name = "Gabriela Mandla-Tucholska";
    let description = "Pozytywna energia i dobry humor nigdy jej nie opuszczają. Poza wieloletnią przygodą z Krav Maga Classic lubi się zmęczyć na siłowni, zajęciach typu Kombat Fitness czy po prostu biegając.";
    let items = ["instruktor Krav Maga Classic - Group Leader", "niebieski pas Krav Maga Classic", "uczestnictwo w kursach, seminariach i obozach organizowanych w ramach Jodan oraz Saario Academy"];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items}/>
    )
}
export default InstructorGabriela;