import React from "react"
import "./Equipment.css"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import AikidoSection from "../components/aikido/AikidoSection";
import ClassesSection from "../components/aikido/ClassesSection";
import FirstClassSection from "../components/aikido/FirstClassSection";
import InstructorSection from "../components/aikido/InstructorSection";

function AikidoHome() {
    return(
        <>
            <Navbar title="Aikido dla dzieci" />
            <TopBar title="Aikido dla dzieci" background={"aikido"}/>
            {/*<OfferSection />*/}
            <AikidoSection />
            <section className="section-wrapper-background">
                <ClassesSection />
            </section>
            <FirstClassSection />
            <section className="section-wrapper-background">
                <InstructorSection />
            </section>
        </>
    )}
export default AikidoHome;