import Instructor from "../../../controls/Instructor";

function InstructorZbigniew({position}) {
    let imageSrc = "/img/instructor/instructor-zbigniew-swiader.jpg";
    let name = "Zbigniew Świąder";
    let description = "Swoją przygodę ze sztukami walki zaczął w 1982 roku od KYOKSUL, potem przez 5 lat trenował KYOKUSHIN, a następnie takie sztuki jak YANG TAI CHI CHUAN,QI GONG,JUDO,WING CHUN,WU SHU." +
        "W 1996 roku pod opieką sensein Romana Hoffmanna rozpoczęła się przygoda z Aikido do 2003 (w 2001 zdał 2-gie kyou). W latach 2003-2007 trenował Karate Shori Ryu u sensei Dariusza Jędzrejczaka - zakończone zdanym 4-tym kyu." +
        "Następnie JuJitsu u sensei Marka Pantkowskiego w 2007 - zakończone 7-mym kyu. W 2011 powrócił do Aikido u sensei Andrzeja Magusiaka.";
    let items = ["instruktor Aikido", "instruktor rekreacji ruchowej - samoobrona od 2011 roku", "instruktor Wing Chun", "instruktor Tai Chi"];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorZbigniew;