import React from "react"
import "./KravMagaClassicSection.css";

function KravMagaClassicSection() {
    return(
        <section className="container krav-maga-classic-section">
            <div className="row">
                <div className="col-12 col-sm-6">
                    <h1>Classic Krav Maga</h1>
                    Krav Maga to w tłumaczeniu "walka w bliskim kontakcie" i jest to system walki stworzony na potrzeby Sił Zbrojnych Izraela (Israeli Defense Forces - IDF) przez <a href="https://pl.wikipedia.org/wiki/Imi_Lichtenfeld" target="_blank" rel="noreferrer">Imiego Lichtenfelda</a>.<br/>
                    Było to kilkadziesiąt skutecznych i prostych technik, skupiających się na naturalnych ludzkich odruchach oraz działaniu w stresie.<br/>
                    <br/>
                    Z czasem Krav Maga ewoluowała, pojawiło się kilka niezależnych organizacji, a ilość technik zaczęła rosnąć.<br/>
                    <br/>
                    <a href="https://saarioacademy.com/classic-krav-maga/" target="_blank" rel="noreferrer">Krav Maga Classic</a> <a href="https://saarioacademy.com" target="_blank" rel="noreferrer">Saario Academy</a> to powrót do korzeni - gdzie ilość technik jest ograniczona, tak aby można było na daną sytuacje zareagować odruchowo - jedną, wielokrotnie ćwiczoną techniką.<br/>
                    <br/>
                    Takie podejście powoduje, że "mniej znaczy więcej".<br/>
                </div>
                <div className="col-12 col-sm-6">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/img/kravka/classic-krav-maga-logo.png"} alt="" />
                </div>
              </div>
            <div className="row">
                <div className="col-12 col-sm-6"></div>
                <div className="col-12 col-sm-6">
                    <figure className="text-center">
                        <blockquote className="blockquote">
                            <p>Nie obawiam się kogoś, kto trenuje 10000 kopnięć, ale tego, który 10000 razy trenował jedno kopnięcie.</p>
                        </blockquote>
                        <figcaption className="blockquote-footer">
                            <cite>Bruce Lee</cite>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </section>
    )}
export default KravMagaClassicSection;