import React from "react"
import "./Equipment.css"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";

function FirstClassSection() {
    return(
        <>
            <Navbar title="Sprzęt" />
            <TopBar title="Sprzęt"/>
            <section className="container first-class-section">
                <div className="row">
                    <div className="col">
                        <h1>Sprzęt treningowy</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-8">
                        <div>
                            <h3>W co się zaopatrzyć nan przysłe treningi?</h3>
                        </div>
                        <div>
                            Przede wszystkim warto mieć własne rękawice bokserskie, ochraniacz na zęby oraz ochraniacz na krocze (suspensor).<br/>
                            W dalszej kolejności warto się zaopatrzyć w buty zapaśnicze (jeśli na sali są maty), ochraniacz na piszczele oraz kask bokserski.<br/>
                            <br/>
                            <hr/>
                            <h4>Rękawice bokserskie</h4>
                            Zrobione ze skóry syntentycznej, naturalnej lub innych materiałów, dostępne w wielu kolorach i motywach. Mogą być wiązane lub zapinane na rzep - i te drugie są najwygodniejsze do zakładania. <br/>
                            Występują także w rozmiarach:<br/>
                            <ul>
                                <li>4 lub 6oz - przeznaczone dla dzieci</li>
                                <li>8oz - dla osób ważących poniżej 50kg lub osób o drobnych dłoniach</li>
                                <li>10oz - dla osób ważących do 65kg - do ćwiczeń technicznych, na przyrządach i lekkich sparingów</li>
                                <li>12oz - dla osób ważących powyżej 65kg - do ćwiczeń technicznych, na przyrządach i lekkich sparingów</li>
                                <li>14-18oz - dla osób szukająych rękawic do sparingów, zapewniają dobrą amortyzację i zmniejsza ryzyko kontuzji podczas walki</li>
                            </ul>
                            <br/>
                            <a href="https://dobrekimona.pl/pl/c/Rekawice-Bokserskie/31" target="_blank" rel="noreferrer">link do przykładowych rękawic >>></a>
                            <hr/>
                            <h4>Ochraniacze na szczękę</h4>
                            Uniemożliwuają "kłapnięcie" zębami o zęby, co może doprowadzić do ich ukruszenia. Najpopularniejsze i nawygodniejsze w użyciu są ochraniacze zakładane na dolną szczękę.<br/>
                            Każdy ochraniacz należy uformować - zanurzając na chwilę w gorącej wodzie, a następnie umieszczając w buzi i zagryzając. Ważne, żeby zacisnąć szczękę w jej naturalnym położeniu.<br/>
                            Na szczęście większość pozawala na ich ponowne uformowanie, gdy potrzebne są poprawki.<br/>
                            Zdaża się, że ochraniacz przeszkadza tj. jest za długi i uciska gardło - w takim wypadku należy odciąć jego końcówkę lub wybrać inny model ochraniacza.<br/><br/>
                            <a href="https://dobrekimona.pl/pl/c/Ochraniacze-na-Zeby/47" target="_blank" rel="noreferrer">link do przykładowych ochraniaczy >>></a><br/>
                            <hr/>
                            <h4>Ochraniacz na krocze</h4>
                            Zdecydowanie warto mieć i nosić na traningach - występują zarówno wersje męskie jak i damskie, w zależności od modelu mogą być zakładane na bieliznę lub na spodenki. Mogą być jednoelementowe lub składać się z ochronnej miseczki oraz części bawełnianej, którą można prać.
                            <br/><br/>
                            <a href="https://dobrekimona.pl/pl/c/Ochraniacze-dla-Kobiet/74" target="_blank" rel="noreferrer">link do przykładowych ochraniaczy krocza dla kobiet >>></a>
                            <a href="https://dobrekimona.pl/pl/c/Ochraniacze-Krocza/34" target="_blank" rel="noreferrer">link do przykładowych ochraniaczy krocza dla mężczyzn>>></a>
                            <hr/>
                            <h4>Owijki bokserskie</h4>
                            Zwane także bandażami, umożliwiają usztywnienie dłoni oraz nadgarstka, zmniejszając tym samym ryzyko kontuzji. Dodatkowo absorbują pot, dzięki czemu mniej przedostaje się do rękawic.<br/>
                            Występują w wielu kolorach i wzorach, mogą być nierozciągliwe lub elastyczne i przeważnie mają długość od 2 to 5 metrów.<br/>
                            Mamy dwie główne techniki wiązania - z owijaniem palców lub bez - najlepiej jest samemu poćwiczyć, na podstawie materiałów na <a href="https://www.youtube.com/results?search_query=techniki+wi%C4%85zania+owijek+bokserskich" target="_blank" rel="noreferrer">YouTube</a>
                            <br/>
                            <br/>
                            <a href="https://dobrekimona.pl/pl/c/Bandaze-Owijki-Bokserskie/30" target="_blank" rel="noreferrer">link do przykładowych owijek bokserskich >>></a>
                            <hr/>
                            <h4>Buty zapaśnicze</h4>
                            Przydatne gdy na sali są specjalne piankowe maty, które mogłyby zostać uszkodzone zwykłymi butami sportowymi. Buty polepszają przyczepność i zmniejszają ryzyko kontuzji.<br/>
                            Najpopularniejsze są Asics Matflex 5/6 oraz Adidas Havoc ale jest więcej firm i modeli.<br/>
                            <br/>
                            <a href="https://dobrekimona.pl/pl/c/Obuwie/86" target="_blank" rel="noreferrer">link do przykładowych butów zapaśniczych >>></a><br/>
                            <a href="https://allegro.pl/listing?string=buty%20zapa%C5%9Bnicze&order=qd" target="_blank" rel="noreferrer">link do butów zapaśniczych na Allegro >>></a><br/>
                            <hr/>
                            <h4>Ochraniacze na piszczele</h4>
                            Dostępne w różnych rozmiarach, długościach i stopniu ochrony - najczęściej chronią albo sam piszczel albo dodatkowo stopę i kostki.<br/>
                            Warto przymierzyć przed zakupem (zwłaszcza te zapewniające pełną ochronę) mając na sobie buty treningowe.<br/>
                            <br/>
                            <a href="https://dobrekimona.pl/pl/c/Ochraniacze-Piszczeli-bez-stopy/76" target="_blank" rel="noreferrer">link do przykładowych ochraniaczy bez stopy >>></a><br/>
                            <a href="https://dobrekimona.pl/pl/c/Ochraniacze-Piszczeli-i-Stopy/33" target="_blank" rel="noreferrer">link do przykładowych ochraniaczy piszczeli i stopy >>></a>
                            <hr/>
                            <h4>Kaski bokserskie</h4>
                            Zmniejszają ryzyko kontuzji szczęki, jeśli są wyposażone w plastykową osłonę lub kratkę, to także chronią nos i oczy. Osłonę lub kratkę można przeważnie zdjąć, przez co taki kask jest bardziej uniwersalny.<br/>
                            <br/>
                            <a href="https://dobrekimona.pl/pl/c/Kaski-do-Karate-Taekwondo-Otwarte/245" target="_blank" rel="noreferrer">link do przykładowych kasków >>></a><br/>
                            <a href="https://dobrekimona.pl/pl/c/Kaski-z-Krata-Metalowa/243" target="_blank" rel="noreferrer">link do przykładowych kasków z kratką >>></a><br/>
                            <a href="https://dobrekimona.pl/pl/c/Kaski-z-Maska-Plastikowa/244" target="_blank" rel="noreferrer">link do przykładowych kasków z plastykową osłoną >>></a>
                            <br/>
                            <br/>
                            <br/>
                            <hr/>
                            Polecamy sklep <a href="https://dobrekimona.pl/kontakt" target="_blank" rel="noreferrer">dobrekimona.pl</a> - duży wybór sprzętu sportowego, fachowa pomoc, można przymierzyć i na hasło "Jodan" jest 10% zniżki.
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 align-items-center align-content-center picture-gray pt-3 text-center">
                        <img className="img-fluid rounded border-3" src="/components/kravka/first-class-section/pexels-allan-mas-5384399-400.jpg" alt="" />
                    </div>
                </div>
            </section>
        </>
    )}
export default FirstClassSection;