import React from "react"
import "./FirstClassSection.css"

function FirstClassSection() {
    return (
        <section className="container first-class-section">
            <div className="row">
                <div className="col">
                    <h1>Pierwszy trening</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-8">
                    <div>
                        <h3>Jak mam się przygotować do pierwszego treningu?</h3>
                    </div>
                    <div>
                        <ul>
                            <li>zabierz ze sobą wygodny strój sportowy - krótkie spodenki, koszulka</li>
                            <li>w zależności od podłoża - możesz trenować w butach sportowych, boso lub specjalnych butach zapaśniczych</li>
                            <li>weź cos do picia</li>
                            <li>oraz ręcznik</li>
                            <li>zjedź coś - ale najpóźniej dwie godziny przed treningiem</li>
                            <li>wydrukuj i wypełnij <a href="/docs/jodan.akido.kids.deklaracja.pdf" download="Jodan Aikido Kids Deklaracja" target="_blank" rel="noreferrer">oświadczenie</a></li>
                            <li>nie możesz wydrukować - nie szkodzi - wypełnisz na miejscu</li>
                            <li>zapoznaj się z regulaminem</li>
                        </ul>
                        <br/>
                    </div>
                    <div>
                        Osoby regularnie ćwiczące na zajęcia przebierają się w kimona - o szczegółach poinformuje prowadzący.
                    </div>
                    <div>
                        <br/>
                        <h3>Regulamin</h3>
                        <ol>
                            <li>Przed wejściem na zajęcia należy wypełnić deklaracje oraz wypełnić i podpisać oświadczenie dotyczące stanu zdrowia ćwiczącego.</li>
                            <li>W razie konieczności należy dostarczyć instruktorowi zaświadczenie wydane przez lekarza posiadającego odpowiednią specjalizację, o braku przeciwwskazań do brania udziału w ćwiczeniach.</li>
                            <li>Podczas zajęć wolno wykonywać tylko te ćwiczenia i techniki, które zastały polecone przez instruktora prowadzącego trening.</li>
                            <li>Organizator zajęć zastrzega sobie prawo do wprowadzania zmian w programie zajęć</li>
                            <li>Wszelkie dolegliwości zdrowotne należy zgłosić opiekunowi/instruktorowi grupy na początku zajęć</li>
                            <li>Należy przychodzić punktualnie na zajęcia. W przypadku spóźnienia należy usiąść w siadzie klęcznym (sei-za) na brzegu prawej strony maty (tatami) i czekać na pozwolenie wejścia przez prowadzącego</li>
                            <li>Na zajęciach dziecko przebywa 50 minut pod opieką instruktora.</li>
                            <li>Organizator nie odpowiada za rzeczy zagubione w czasie zajęć</li>
                            <li>Opłaty należy uiszczać do 10 dnia każdego miesiąca lub nie później niż na drugich zajęciach w miesiącu.</li>
                            <li>Niewykorzystanie opłaconych zajęć nie jest podstawą do zwolnienia z opłat w kolejnym okresie rozliczeniowym</li>
                        </ol>
                    </div>
                </div>
                <div className="col-12 col-sm-4 align-items-center align-content-center picture-gray pt-3 text-center">
                    <img className="img-fluid rounded border-3" src="/components/kravka/first-class-section/pexels-allan-mas-5384399-400.jpg" alt=""/>
                </div>
            </div>
        </section>
    )
}

export default FirstClassSection;