import React from "react"
import "./AboutJodanSection.css"

function AboutJodanSection() {
    return(
        <section className="container about-jodan-section">
            <div className="row">
                <div className="d-none d-sm-flex col-sm-3 align-items-center align-content-center picture-gray pt-3 text-center">
                    <img className="img-fluid rounded border-3" src="/components/home/about-jodan-section/logo-kids-flat-500.jpg" alt="" />
                </div>
                <div className="col-12 col-sm-6">
                    <h1>O Jodan</h1>
                    <p className="description">
                        Jodan to grupa ludzi, pasjonujących się sztukami i systemami walk, rozwojem osobistym, utrzymaniem sprawności fizycznej oraz pracą z ludźmi.<br/>
                    </p>
                    <p>
                        Chętnie przekazują swoją wiedzę, doświadczenie i umiejętności, "zarażający" swoim zapałem innych - tak aby móc potem obserwować ich postępy i rozwój.<br/>
                    </p>
                    <p>
                        To także doświadczeni opiekunowie, którzy pełni energii i pomysłów sprawią, że zarówno mali jak i duzi przeżyją niezapomniane chwile podczas wspólnych obozów i wyjazdów.
                    </p>
                </div>
                <div className="d-none d-sm-flex col-sm-3 align-items-center align-content-center picture-gray pt-3 text-center">
                    <img className="img-fluid rounded border-3" src="/components/home/about-jodan-section/logo-flat-500.jpg" alt="" />
                </div>
            </div>
            <div className="row">
                <div className="col-6 d-sm-none">
                    <img className="img-fluid rounded border-3" src="/components/home/about-jodan-section/logo-kids-flat-500.jpg" alt=""/>
                </div>
                <div className="col-6 d-sm-none">
                    <img className="img-fluid rounded border-3" src="/components/home/about-jodan-section/logo-flat-500.jpg" alt=""/>
                </div>
            </div>
        </section>
    )
}

export default AboutJodanSection;