import Instructor from "../../../controls/Instructor";

function InstructorSzymon({position}) {
    let imageSrc = "/img/instructor/instructor-szymon-piotrowski.jpg";
    let name = "Szymon Piotrowski";
    let description = "Od najmłodszych interesuje się i intensywnie trenuje sporty walki takie jak Aikido, Krav Maga, Bjj, Boks. Są zarówno jego pasją, ale także wiąże z nimi swoją przyszłość.";
    let items = ['niebieski pas Classic Krav Maga', 'Aikido - 5-kyo', 'udział w licznych kurach, seminariach i zgrupowaniach w ramach m.in. Jodan oraz Saario Academy'];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorSzymon;