import Instructor from "../../../controls/Instructor";

function InstructorMagdalenaL({position}) {
    let imageSrc = "/img/instructor/instructor-magdalena-liberacka.jpg";
    let name = "Magdalena Liberacka";
    let description = "Wesoła i pełna energii, zawsze gotowa na nowe wyzwania i aktywności. W chwilach odpoczynku poprowadzi zajęcia z japonistyki oraz przybliży Wam kulturę i zwyczaje kraju kwitnącej wiśni.";
    let items = ["wychowawca dziecięcy", "prowadzi zajęcia z japonistyki"];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorMagdalenaL;