import Instructor from "../../../controls/Instructor";

function InstructorMarcin({position}) {
    let imageSrc = "/img/instructor/instructor-marcin-lipa.jpg";
    let name = "Marcin Lipa";
    let description = "Trenuje Aikido od 13 roku życia. Sport zawsze był i jest częścią jego życia. Jeśli nie sztuki walki to bieganie, windsurfing, piłka nożna lub gra na gitarze.";
    let items = ['instruktor Aikido - 2 dan, <a href="https://www.aikikai.com.pl/aikido/shoryukai-polska" target="_blank" rel="noreferrer">WKAA</a>',
        'stale rozwija swoją wiedzę i umiejętności pod okiem <a href="https://www.aikikai.com.pl/klub/sensei-pawel-zdunowski" target="_blank" rel="noreferrer">Sensei Pawła Zdunowskiego - 6 dan</a>',
        'instruktor rekreacji ruchowe ze specjalnością samoobrona',
        'instruktor żeglarstwa deskowego od 2014 roku',
        'trener II klasy piłki nożnej i trener piłki nożnej UEFA C od 2016 roku',
        'wychowawca dzieci i młodzieży na koloniach i obozach od 2011 roku'];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items}/>
    )
}
export default InstructorMarcin;