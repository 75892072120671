import "./styles.css"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import Camp2024Summer from "../components/camps/Camp2024Summer";
import InstructorSection from "../components/camps/InstructorSection";
function CampHome() {
    return (
        <>
            <Navbar title="Obozy i wyjazdy" />
            <TopBar title="Obozy i wyjazdy" background={"camp"}/>
            <Camp2024Summer />
            <InstructorSection />
        </>
    );
}

export default CampHome;