import React from "react"
import "./AikidoSection.css";

function AikidoSection() {
    return(
        <section className="container aikido-section">
            <div className="row">
                <div className="col-12 col-sm-8">
                    <h1>Aikido dla dzieci</h1>
                    <p>
                    Jodan Aikido jest klubem sztuk walki założonym wiosną 2011 roku z inicjatywy Pawła Piotrowskiego. Pierwsza sekcja powstała przy Szkole Podstawowej nr 10 na warszawskiej Ochocie. Poza klasycznym Aikido prowadzone są również zajęcia dla dorosłych, opierające się na technikach pochodzących z innych, bardziej kontaktowych sztuk walki, jak Muay Thai, Krav Maga, czy Karate.
                    </p>
                    <p>
                    Obecnie prowadzone są zajęcia koedukacyjne dla dorosłych i młodzieży oraz zajęcia AIKIDO DLA DZIECI. Podczas treningów dla dzieci szczególnie duża waga przykładana jest do ćwiczeń ogólnorozwojowych, poprawiających kondycję i zdrowie. Zapisy trwają cały rok – każdy może dołączyć w dowolnym terminie.
                    </p>
                    <p>
                    Dzieci podczas treningów przede wszystkim dobrze się bawią, rozwijając przy okazji zarówno sprawność fizyczna jak i koordynację. Duże znaczenie dla trenera ma wytworzenie przyjaznej atmosfery w grupie - równie ważna jak sprawność fizyczna jest koleżeńskość i fair play. Dzieci uczą się, że jedynie wspołdziałając są w stanie osiągnąć cel.
                    </p>
                    <p>
                    Aikido jest sztuką walki bez walki. Głównym założeniem jest wykorzystanie siły przeciwnika przeciwko niemu samemu. Dlatego też podczas treningów nie ma żadnego rodzaju konfrontacji czy pojedynków - techniki Aikido służą tylko i wyłącznie samoobronie. Z tej filozofii wynika też brak technik ofensywnych, jak kopnięcia czy uderzenia - Aikidoka pozostaje w harmonii z naturą, jego ruchy są płynne i pozbawione agresji.
                    </p>
                    <p>
                    Nawet najmłodsi odnajudują się podczas zajęć. Starsze dzieci pracują nad doskonaleniem technik i osiągniem kolejnych kyu - stopni, marząc o hakamie (szerokie, zazwyczaj czarne spodnie, oznaczające w Aikido stopień mistrzowski). Młodsi świetnie się bawią podczas gier i zabaw ruchowyc powoli wprowadzających w świat Aikido, a jednocześnie rozwijających ciało i umysł.
                    </p>
                    <p>
                    Twórcą AIKIDO jest o'sensei Morihei Ueshiba, który na przełomie XIX i XX wieku skodyfikował znaną nam dziś sztukę walki. Mimo pozornie krótkiego rodowodu korzenie Aikido sięgają daleko w przeszłość. Rzuty i dźwignie wywodzą się z na poły mitycznego Aikijuitsu - sztuki walki mającej umożliwić wysadzonemu z siodła i pozbawionemu miecza samurajowi przeżycie. To też jest powodem faktu, iż duża część dźwigni zakładana jest na nadgarstek czy palce - jedyne część ciała nie zakryte zbroją. Techniki poruszania się oraz walki bokkenem (drewniany miecz treningowy) wywodzą się z tradycyjnej japońskiej szermierki - kenjuitsu, natomiast walka jo (drewniany kij) oparta jest na walce samurajską włócznią yari
                    </p>
                    <p>
                    Aikido, jak żadna inna sztuka walki kładzie nacisk na rozwój duchowy ucznia. Równie ważne co doskonałość techniczna są kwestie etyczne i moralne. Uczeń cały czas czuje odpowiedzialność za zdrowie i życie drugiego człowieka - zarówno partnera podczas treningu, jak i podczas realnej walki. Twórca Aikido powiedział, że jego celem nie jest nauczyć ruszać ciałem, lecz ruszać głową.
                    Obecnie Aikido uprawiane jest na całym świecie, w ramach kilkunastu szkół o mniej lub bardziej praktycznym nastawieniu. W dużej mierze to, co było pierwotnie sztuką walki spełnia rolę rekreacyjno-zdrowotną, swoistej gimnastyki. Niemniej jednak pozostaje jedną z niewielu sztuk walki dostosowaną do konfrontacji z wieloma przeciwnikami naraz. Na poziomie mistrzowskim możliwa jest obrona bez powodowania stałych obrażeń u agresora, a efektywność poprawnie wykonanych technik nie zależy od masy ani wzrostu. I nie bez kozery zwana jest sztuką walki dla gentlemanów - mistrz Aikido otoczony przeciwnikami jest jak tancerz wśród szarpanych wiatrem liści.
                    </p>
                </div>
                <div className="col-12 col-sm-4">
                    <br/>
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/img/aikido/osensei.jpg"} alt="" />
                </div>
              </div>
        </section>
    )}
export default AikidoSection;