import Instructor from "../../../controls/Instructor";

function InstructorIstvan({position}) {
    let imageSrc = "/img/instructor/instructor-istvan-nagy.jpg";
    let name = "Istvan Nagy";
    let description = "Współzałożyciel i kluczowa postać organizacji Saario Academy, która promuje i prowadzi takie style jak: Jyrki Saario Defendo, Classic Krav Maga, Safe Kid, Safe Teen, Ironman Grappling i Ironman Kickboxing";
    let items = ['instruktor Krav Maga – 4 stopień czarny pas Classic Krav Maga',
        'instruktor Defendo – 4 stopień czarny pas Defendo',
        'instruktor Safe Kid',
        'ekspert w dziedzinie samoobrony',
        'szef wyszkolenia Classic Krav Maga Network w Polsce',
        'szef wyszkolenia Defendo Alliance w Polsce',
        'jeden z głównych instruktorów Saario Academy'];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorIstvan;