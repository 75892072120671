import React from "react"
import "./ClassesSection.css";
import {JodanConfiguration as Configuration} from "../../data/JodanConfiguration";

function ClassesSection() {

    return(
        <section className="container classes-section" id="classes-section">
            <div className="row">
                <div className="col">
                    <h1>Zajęcia Krav Maga</h1>
                </div>
            </div>
            <div className="row">
                <div className="col"><hr/></div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <span><b>Poniedziałki</b> i <b>środy</b> od <b>20:00</b> do <b>21:00</b>.</span>
                    <br/><br/>
                    <h5><a href="https://maps.app.goo.gl/hfENQvMreVvNbsD76" target="_blank" rel="noreferrer">Klub Artia</a></h5>
                    Władysława Korotyńskiego 23/7<br/>
                    02-123 Warszawa<br/>
                    <a href="https://maps.app.goo.gl/hfENQvMreVvNbsD76" target="_blank" rel="noreferrer">link >></a><br/>
                </div>
                <div className="col-12 col-sm-6">
                    <iframe title="Klub Artia" src={Configuration.artiaGmaps} width="100%" height="100%" style={{border: "0", padding: "0.5em"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            <div className="row">
                <div className="col"><hr/></div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <span><b>Wtorki</b> i <b>czwartki</b> od <b>20:00</b> do <b>21:30</b>.</span>
                    <br/><br/>
                    <h5><a href="https://maps.app.goo.gl/itrR35kWpAo11e5eA" target="_blank" rel="noreferrer">Szkoła Podstawowa Chocimska</a></h5>
                    Kielecka 44<br/>
                    02-530 Warszawa<br/>
                    <a href="https://maps.app.goo.gl/itrR35kWpAo11e5eA" target="_blank" rel="noreferrer">link >></a><br/>
                </div>
                <div className="col-12 col-sm-6">
                    <iframe title="Szkoła Podstawowa Chocimska" src={Configuration.kieleckaGmaps} width="100%" height="100%" style={{border: "0", padding: "0.5em"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="row">
                <div className="col"><hr/></div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>Zapisy</h3>
                    <img src="/components/contact-section/phone-call-48w.png" alt=""/> Telefonicznie: {Configuration.contactPhone}
                    <br/><br/>
                    <img src="/components/contact-section/email-mono-48w.png" alt=""/> E-mailem: {Configuration.contactEmail}
                    <br/><br/>
                    <img src="/components/contact-section/dojo-48w.png" alt=""/> Na miejscu
                </div>
            </div>
        </section>
    )}
export default ClassesSection;