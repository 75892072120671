import Instructor from "../../controls/Instructor";

function InstructorPawel({position}) {
    let imageSrc = "/img/instructor/instructor-pawel-piotrowski.jpg";
    let name = "Paweł Piotrowski";
    let description = "Od przeszło 25 lat jest pasjonatem sztuk i systemów walki oraz samorozwojem ale przede wszystkim przekazywaniem swojej wiedzy i umiejętności osobom uczęszczającym na zajęcia.<br/>" +
        "Aikido trenuje od 1995 roku, w międzyczasie przez rok trenował Takewondo, Karate oraz Muai Thai pod okiem Łukasza Rola (klub Palestra). Następnie przez 3 lata trenował Grom Combat, wraz z jednostkami specjalnymi.";
    let items = ['instruktor Krav Maga - 2 stopień, czarny pas Classic Krav Maga - 2 dan',
        'instruktor Defendo - 2 stopień Combat Tech Defendo',
        'instruktor Aikido - 3 dan, <a href="https://www.aikikai.com.pl/aikido/shoryukai-polska" target="_blank" rel="noreferrer">egzaminator w stopniu Fukushidoin PFA</a>',
        'instruktor Safe Kids i Safe Teens',
        'opiekun, trener i organizator letnich i zimowych obozów dla dzieci i dorosłych',
        'stale rozwija swoją wiedzę i umiejętności w zakresie samoobrony dzięki uczestnictwu w licznych szkoleniach (np. pałka teleskopowa, obrona przed nożem) - zarówno w Polsce jak i za granicą',
        'jak również w Aikido - pod okiem <a href="https://www.aikikai.com.pl/klub/sensei-pawel-zdunowski" target="_blank" rel="noreferrer">Sensei Pawła Zdunowskiego - 6 dan</a>'];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorPawel;