import "./styles.css"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import ClassesSection from "../components/home/ClassesSection";
function ClassesHome() {
    return (
        <>
            <Navbar title="Krav Maga" />
            <TopBar title="Krav Maga"/>
            <ClassesSection />
        </>
    );
}

export default ClassesHome;