import React from "react";
import "./Credits.css";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";

function Credits() {
    return (
        <>
            <Navbar title="Materiały" />
            <TopBar title="Materiały"/>
            <section className="container credits-section">
                <div className="row align-items-center">
                    <h3>Materiały i podziękowania</h3>
                    <span>Na stronie zostały użyte materiały oraz elementy graficzne twórców oraz dostępne na portalach:<br/></span>
                    <h4>Ikony</h4>
                    <a href="https://www.flaticon.com/free-icons/boxing" target="_blank" rel="noreferrer" title="boxing icons">Boxing icons created by Smashicons - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/boxing-ring" target="_blank" rel="noreferrer" title="boxing ring icons">Boxing ring icons created by Radhe Icon - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/dojo" target="_blank" rel="noreferrer" title="dojo icons">Dojo icons created by Freepik - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/facebook" target="_blank" rel="noreferrer" title="facebook icons">Facebook icons created by Freepik - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/fight" target="_blank" rel="noreferrer" title="fight icons">Fight icons created by Eucalyp - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/instagram" target="_blank" rel="noreferrer" title="instagram icons">Instagram icons created by Freepik - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/phone" target="_blank" rel="noreferrer" title="phone icons">Phone icons created by Prosymbols - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/phone" target="_blank" rel="noreferrer" title="phone icons">Phone icons created by Gregor Cresnar - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/physical-activity" target="_blank" rel="noreferrer" title="physical activity icons">Physical activity icons created by Eucalyp - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/sport-equipment" target="_blank" rel="noreferrer" title="sport equipment icons">Sport equipment icons created by Eucalyp - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/sports-and-competition" target="_blank" rel="noreferrer" title="sports and competition icons">Sports and competition icons created by Eucalyp - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/sports-and-competition" target="_blank" rel="noreferrer" title="sports and competition icons">Sports and competition icons created by Smashicons - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/sports-and-compettion" target="_blank" rel="noreferrer" title="sports and competition icons">Sports and competition icons created by Eucalyp - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/email" target="_blank" rel="noreferrer" title="email icons">Email icons created by Freepik - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/instagram-logo" target="_blank" rel="noreferrer" title="instagram logo icons">Instagram logo icons created by Pixel perfect - Flaticon</a><br/>
                    <a href="https://www.flaticon.com/free-icons/facebook" target="_blank" rel="noreferrer" title="facebook icons">Facebook icons created by Freepik - Flaticon</a><br/>

                    <h4>Obrazki</h4>
                    <a href="https://pexels.com" target="_blank" rel="noreferrer">pexels.com</a><br/>
                    <a href="https://pixabay.com" target="_blank" rel="noreferrer">pixabay.com</a>
                </div>
            </section>
        </>
    );
}

export default Credits;