import Instructor from "../../../controls/Instructor";

function InstructorTadeusz({ position }) {
    let imageSrc = "/img/instructor/instructor-tadeusz-uherek.jpg";
    let name = "Tadeusz Uherek";
    let description = "Krav Magą a później Krav Maga Classic zainteresował się lata temu i do dzisiaj pozostaje pasjonatem tego systemu walki. Okazjonalnie trenuje także inne dyscypliny jak Muai Thai czy Kickboxing, ale lubi także pojawić się na siłowni czy innych zajęciach.";
    let items = ["instruktor Krav Maga Classic - Group Leader", "niebieski pas Krav Maga Classic", "uczestnictwo w kursach, seminariach i obozach organizowanych w ramach Jodan oraz Saario Academy"];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorTadeusz;