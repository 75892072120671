import React from "react"
import "./ClassesSection.css";
import {JodanConfiguration as Configuration} from "../../data/JodanConfiguration";

function ClassesSection() {

    return(
        <section className="container classes-section" id="classes-section">
            <div className="row">
                <div className="col">
                    <h1>Zajęcia Aikido Kids</h1>
                </div>
            </div>
            <div className="row">
                <div className="col"><hr/></div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <h5><a href="https://maps.app.goo.gl/r3nHuMY5r7VzDKJRA" target="_blank" rel="noreferrer">Dom Kultury Rakowiec</a></h5>
                    Wiślicka 8<br/>
                    02-114 Warszawa<br/>
                    <a href="https://maps.app.goo.gl/r3nHuMY5r7VzDKJRA" target="_blank" rel="noreferrer">link >></a><br/>
                    <br/>
                    <h6>Poniedziałki i środy</h6>
                    16:00 - 16:40 najmłodsi (4-6 lat)<br/>
                    16:50 - 17:40 grupa starsza (7-9 lat)<br/>
                    17:50 - 18:50 najstarsi (10-17 lat)<br/>
                </div>
                <div className="col-12 col-sm-6">
                    <iframe title="Dom Kultury Rakowiec" src={Configuration.wislickaGmaps} width="100%" height="100%" style={{border: "0", padding: "0.5em"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <hr/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6">
                    <h5><a href="https://maps.app.goo.gl/itrR35kWpAo11e5eA" target="_blank" rel="noreferrer">Szkoła Podstawowa Chocimska</a></h5>
                    Kielecka 44<br/>
                    02-530 Warszawa<br/>
                    <a href="https://maps.app.goo.gl/itrR35kWpAo11e5eA" target="_blank" rel="noreferrer">link >></a>
                    <br/><br/>
                    <h6>Wtorek</h6>
                    18:00 grupa starsza (9-16 lat)<br/>
                    19:00 zajęcia indywidualne <br/>
                    <br/>
                    <h6>Czwartek</h6>
                    17:00 najmłodsi (4-7 lat)<br/>
                    18:00 grupa starsza (9-16 lat)<br/>
                </div>
                <div className="col-12 col-sm-6">
                    <iframe title="Szkoła Podstawowa Chocimska" src={Configuration.kieleckaGmaps} width="100%" height="100%" style={{border: "0", padding: "0.5em"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="row">
                <div className="col"><hr/></div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>Zapisy</h3>
                    <img src="/components/contact-section/phone-call-48w.png" alt=""/> Telefonicznie: {Configuration.contactPhone}
                    <br/><br/>
                    <img src="/components/contact-section/email-mono-48w.png" alt=""/> E-mailem: {Configuration.contactEmail}
                    <br/><br/>
                    <img src="/components/contact-section/dojo-48w.png" alt=""/> Na miejscu
                </div>
            </div>
        </section>
    )}
export default ClassesSection;