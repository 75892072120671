import Instructor from "../../../controls/Instructor";

function InstructorMagdalenaJ({position}) {
    let imageSrc = "/img/instructor/instructor-magdalena-janczak.jpg";
    let name = "Magdalena Jańczak";
    let description = "Zawsze pozytywna i uśmiechnięta, od wielu lat uczestniczy w obozach i wyjazdach, opiekując się dziećmi i młodzieżą.";
    let items = ["wychowawca dziecięcy"];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorMagdalenaJ;