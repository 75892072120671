import React from "react"
import "./BottomMenu.css"
import {JodanConfiguration as Configuration} from "../data/JodanConfiguration"
import {Link} from "react-router-dom";
function BottomMenu() {

    return(
        <section className="container bottom-menu-section text-center_ text-md-end">
            <span>© 2024 Tadeusz Uherek</span>
            |
            <span>
                <Link to="/materialy">Użyte materiały</Link>
            </span>
            |
            <span>
                <a href={Configuration.jodanKravMagaFacebook} target="_blank" rel="noreferrer"><img src="/components/bottom-menu/facebook-32.png" alt="Fb"/></a>
                &nbsp;
                &nbsp;
                <a href={Configuration.jodanKravMagaInstagram} target="_blank" rel="noreferrer"><img src="/components/bottom-menu/instagram-32.png" alt="In"/></a>
            </span>
        </section>
    )}
export default BottomMenu;