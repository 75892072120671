import "./styles.css"
import OfferSection from "../components/kravka/OfferSection";
import ClassesSection from "../components/kravka/ClassesSection";
import InstructorSection from "../components/kravka/InstructorSection";
import FirstClassSection from "../components/kravka/FirstClassSection";
import KravMagaClassicSection from "../components/kravka/KravMagaClassicSection";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
function KravkaHome() {
    return (
        <>
            <Navbar title="Krav Maga" />
            <TopBar title="Krav Maga"/>
            <OfferSection />
            <section className="section-wrapper-background">
                <KravMagaClassicSection />
            </section>
            <ClassesSection />
            <section className="section-wrapper-background">
                <FirstClassSection />
            </section>
            <InstructorSection />
        </>
    );
}

export default KravkaHome;