import Instructor from "../../controls/Instructor";

function InstructorPrzemyslaw({position}) {
    let imageSrc = "/img/instructor/instructor-przemyslaw-gorski.jpg";
    let name = "Przemysław Górski";
    let description = "Od ok. 2000 roku uprawia Brazylijskie Ju Jitsu - od momentu, gdy tylko pojawiła się piewsza sekcja tego sportu w Warszawie. Pasjonat oraz trener tej dyscypliny. Interesuje się także psychologią sportu ora treningiem metnalnym." +
        "Swoim uczniom poza technikami, wpaja również zasady fair play, szacunek do innych oraz zasady bezpieczeństwa.";
    let items = ['instruktor Brazylijskiego Jiu Jitsu - czarny bas BJJ',
        'ceftyfikowany przez Polską Akademię Sportu instruktor samoobrony i sportów walki',
        'uczy MMA i przygotowuje zawodników do walk w klatce',
        'stale rozwija swoją wiedzę i umiejętności w zakresie samoobrony dzięki uczestnictwu w licznych szkoleniach - zarówno w Polsce jak i za granicą'];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorPrzemyslaw;