import "./styles.css"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import InstructorsSection from "../components/home/InstructorsSection";
function InstructorsHome() {
    return (
        <>
            <Navbar title="Instruktorzy" />
            <TopBar title="Instruktorzy"/>
            <InstructorsSection />
        </>
    );
}

export default InstructorsHome;