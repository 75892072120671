import Instructor from "../../../controls/Instructor";

function InstructorArkadiusz({position}) {
    let imageSrc = "/img/instructor/instructor-arkadiusz-kryszewski.jpg";
    let name = "Arkadiusz Kryszewski";
    let description = "Wieloletni pasjonat i instruktor systemów walki oraz samoobrony. Swoim entuzjazmem i pasją zaraża zarówno dorosłych, młodzież jak i najmłodszych.";
    let items = ['instruktor Classic Krav Maga – Group Leader Classic Krav Maga',
        'instruktor Defendo – Hard Target',
        'instruktor Safe Kid',
        'instruktor samoobrony'];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorArkadiusz;