import "./Navbar.css"
import {HashLink} from "react-router-hash-link";
import { Link } from "react-router-dom";
function Navbar() {
    return(
        <nav className="navbar navbar-expand-sm bg-body-tertiary top-navbar">
            <div className="container-md">
                {/*<a className="navbar-brand" href="#">Navbar</a>*/}
                <div className="navbar-header">
                    <a className="navbar-brand" href="/">
                        <img className="logo-image" src={process.env.PUBLIC_URL + "/img/jodan-logo-full.png"} alt="" />
                    </a>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/aikido">Aikido</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/kravka">Krav Maga</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/obozy">Obozy</Link>
                        </li>
                        <li className="nav-item">
                            <HashLink smooth className="nav-link" to="/zajecia">Zajęcia</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink smooth className="nav-link" to="/instruktorzy">Instruktorzy</HashLink>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/kontakt">Kontakt</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;