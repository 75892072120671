import React from "react"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import "./OfferSection.css"
import "../../pages/styles.css"
import {HashLink} from "react-router-hash-link";

const groupClasses = (
    <Popover>
        <Popover.Header as="h3"><img src="/components/kravka/offer-section/icon-race-48w.png" alt="" />Zajęcia grupowe</Popover.Header>
        <Popover.Body>
            Zapraszamy na zajęcia grupowe:<br/><br/>
            <h5>Klub Artia, Korotyńskiego:</h5>
            poniedziałek, środa na 20:00<br/><br/>
            <h5>SP Chocimska, Kielecka:</h5>
            wtorek, czwrtek na 20:00<br/><br/>
        </Popover.Body>
    </Popover>
);
const individualClasses = (
    <Popover>
        <Popover.Header as="h3"><img src="/components/kravka/offer-section/icon-boxing-48w.png" alt="" />Zajęcia indywidualne</Popover.Header>
        <Popover.Body>!
            Trening indywidualny to idealny sposób na szybkie podniesienie swoich umiejętności i technik. Froma i intensywność ćwiczeń jest dostosowywana do Twoich potrzeb, możliwości i oczekiwań - tak aby zmaksymalisować korzyści.<br/><br/>
            Częstotliwość, terminy i miejsca spotkań są ustalene indywidualnie - tak aby móc je dopasować do Twoich zajęć.<br/><br/>
            Skontaktuj się z nami aby poznać szczegóły i umówić się na pierwsze zajęcia.<br/><br/>
            {/*<HashLink smooth className="" to="/kravka#classes-section">Przejdź do kontaktu...</HashLink>*/}
        </Popover.Body>
    </Popover>
);
const additionalEvents = (
    <Popover>
        <Popover.Header as="h3"><img src="/components/kravka/offer-section/icon-boxing-ring-48w.png" alt="" />Dodatkowe wydarzenia</Popover.Header>
        <Popover.Body>
            Dodatkowe wydarzenia takie jak treningi (szlifowanie technik, sparingi itp.) i szkolenia tematyczne (obrona przed nożem, użycie pałki teleskopowej itp.) odbywające się w ramach Jodan lub Saario Academy.<br/><br/>
            Wysyłamy informacje o takich spotkaniach do uczestników naszych zajęć.<br/><br/>
        </Popover.Body>
    </Popover>
);
const gainSelfConfidence = (
    <Popover>
        <Popover.Header as="h3"><img src="/components/kravka/offer-section/icon-hand-48w.png" alt="" />Pewność siebie</Popover.Header>
        <Popover.Body>
            Regularne uczestnictwo na zajęciach wzmocni i rozwinie Twoją pewność siebie - to się przyda zarówno na ulicy jak i w codziennym życiu!
        </Popover.Body>
    </Popover>
);
const strenghtenYourMindAndBody = (
    <Popover>
        <Popover.Header as="h3"><img src="/components/kravka/offer-section/icon-pilates-48w.png" alt="" />Wzmocnij ciało i umysł</Popover.Header>
        <Popover.Body>
            Regularne uczestnictwo na zajęciach zwiększy Twoją siłę, prędkość i wytrzymałość, a trenerzy i atmosfera przyjaznej rywalizacji będzie Cię motywowała działania!
        </Popover.Body>
    </Popover>
);

const beSafe = (
    <Popover>
        <Popover.Header as="h3"><img src="/components/kravka/offer-section/icon-boxing-gloves-48w.png" alt="" />Ćwicz bezpiecznie</Popover.Header>
        <Popover.Body>
            Ćwicz bezpiecznie i baw się dobrze.<br/><br/>
            Dokładamy wszelkich starań, aby trening był dla Was bezpieczny - priorytetem jest abyście opuścili salę w takim stanie w jakim na nią weszliście... tylko bardziej zmęczeni i zadowoleni!<br/><br/>
            Chcemy się z Wami zobaczyć na następnym treningu!
        </Popover.Body>
    </Popover>
);

function OfferSection() {
    return(
        <section className="container offer-section">
            <div className="row align-items-center">
                <div className="col-sm-4 picture picture-gray">
                    <img className="img-fluid rounded border-3" src="/components/kravka/offer-section/picture.jpg" alt="" />
                </div>
                <div className="col-sm-8">
                    <div className="row row-cols-2 row-cols-lg-3">
                        <div className="col center">
                            <OverlayTrigger trigger="click" rootClose placement="auto" data-bs-trigger="focus" overlay={groupClasses}>
                                <button className="popover-link">
                                    <img src="/components/kravka/offer-section/icon-race-48w.png" alt=""/>
                                    <h2>Zajęcia grupowe</h2>
                                </button>
                            </OverlayTrigger>
                            <HashLink smooth className="" to="/kravka#classes-section">Więcej informacji...</HashLink>
                        </div>
                        <div className="col">
                            <OverlayTrigger trigger="click" rootClose placement="auto" data-bs-trigger="focus" overlay={individualClasses}>
                                <button className="popover-link">
                                    <img src="/components/kravka/offer-section/icon-boxing-48w.png" alt=""/>
                                    <h2>Treningi indywidualne</h2>
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="col">
                            <OverlayTrigger trigger="click" rootClose placement="auto" data-bs-trigger="focus" overlay={additionalEvents}>
                                <button className="popover-link">
                                    <img src="/components/kravka/offer-section/icon-boxing-ring-48w.png" alt=""/>
                                    <h2>Dodatkowe wydarzenia</h2>
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="col">
                            <OverlayTrigger trigger="click" rootClose placement="auto" data-bs-trigger="focus" overlay={gainSelfConfidence}>
                                <button className="popover-link">
                                    <img src="/components/kravka/offer-section/icon-hand-48w.png" alt=""/>
                                    <h2>Pewność siebie</h2>
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="col">
                            <OverlayTrigger trigger="click" rootClose placement="auto" data-bs-trigger="focus" overlay={strenghtenYourMindAndBody}>
                                <button className="popover-link">
                                    <img src="/components/kravka/offer-section/icon-pilates-48w.png" alt=""/>
                                    <h2>Wzmocnij ciało i umysł</h2>
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="col">
                            <OverlayTrigger trigger="click" rootClose placement="auto" data-bs-trigger="focus" overlay={beSafe}>
                                <button className="popover-link">
                                    <img src="/components/kravka/offer-section/icon-boxing-gloves-48w.png" alt=""/>
                                    <h2>Ćwicz bezpiecznie</h2>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OfferSection;