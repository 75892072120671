export class JodanConfiguration {
    static contactPhone = "798 625 520";
    static contactEmail = "info@jodan.pl";
    static contactCampEmail = "jodan.obozy@gmail.com";
    static jodanKravMagaFacebook = "https://www.facebook.com/JodanKravMaga";
    static jodanKravMagaInstagram = "https://www.instagram.com/jodan_academy";
    static artiaGmaps = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d844.2888657950031!2d20.963384103349153!3d52.20271013904414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd9492683781%3A0xccddb1fb4bb6fdb9!2sArtia%20Akademia%20Artystyczna!5e0!3m2!1sen!2sde!4v1699290634074!5m2!1sen!2sde";
    static kieleckaGmaps = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1222.5225004904264!2d21.00432107300068!3d52.20622970510926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eccc54574adcf%3A0x1618e2de7d1fad23!2sKielecka%2044%2C%2002-530%20Warszawa!5e0!3m2!1sen!2spl!4v1701278511251!5m2!1sen!2spl";
    static wislickaGmaps = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.205091026933!2d20.975735413355217!3d52.203320371863185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecd85c87b30a7%3A0xce81f238ae76a45a!2sDK%20Rakowiec%2C%20Dom%20Kultury%20Rakowiec!5e0!3m2!1spl!2spl!4v1704232321012!5m2!1spl!2spl";
}
