import PropTypes from "prop-types";

function Instructor({ position, imageSrc, name, description, items }) {
    function image() {
        return (
            <div className="d-none d-sm-flex col-sm-4 align-items-center text-center picture">
                <img className="img-fluid rounded-pill border-white border-3" src={imageSrc} alt=""/>
            </div>
        );
    }

    return (
        <>
        <div className="row">
            <div className="col-12 d-sm-none align-items-center text-center picture">
                <img className="img-fluid rounded-pill border-white border-3 w-50" src={imageSrc} alt=""/>
            </div>
        </div>
        <div className="row">
        {position === "left" ? (image()) : ""}
            <div className="col-12 col-sm-8">
                <div>
                    <h2>{name}</h2>
                </div>
                <div>
                    <span dangerouslySetInnerHTML={{__html: description}}/>
                    <br/><br/>
                    <ul>
                        {items.map((element, index) => (
                            <li key={index} dangerouslySetInnerHTML={{__html: element}}/>
                        ))}
                    </ul>
                </div>
            </div>
            {position !== "left" ? (image()) : ""}
        </div>
        </>
    )
}

Instructor.propTypes = {
    image: PropTypes.oneOf(['left', 'right']).isRequired,
    imageSrc: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
};
Instructor.defaultProps = {
    image: "left",
}
export default Instructor;