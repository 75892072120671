import React from "react"
import "./ClassesSection.css"

function ClassesSection() {
    return(
        <section className="container classes-section">
            <div className="row text-center">
                <div className="col-12">
                    <h1>Grafik zajęć</h1>
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered border-secondary-subtle w-100">
                            <thead>
                            <tr>
                                <th></th>
                                <th>PON</th>
                                <th>WT</th>
                                <th>ŚR</th>
                                <th>CZW</th>
                                <th>PT</th>
                                <th>SB</th>
                                <th>ND</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>10:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <p className="safekids">
                                        10:00 - 10:45<br/>
                                        Safe Kids<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>11:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>12:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>13:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>14:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>15:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>16:00</td>
                                <td>
                                    <p className="aikido">
                                        16:00 - 16:40<br/>
                                        AIKIDO najmłodsi (4-6 lat)<br/>
                                        Wiślicka 8
                                    </p>
                                    <p className="aikido">
                                        16:50 - 17:40<br/>
                                        AIKIDO starsi (7-9 lat)<br/>
                                        Wiślicka 8
                                    </p>
                                </td>
                                <td></td>
                                <td>
                                    <p className="aikido">
                                        16:00 - 16:40<br/>
                                        AIKIDO najmłodsi (4-6 lat)<br/>
                                        Wiślicka 8
                                    </p>
                                    <p className="aikido">
                                        16:50 - 17:40<br/>
                                        AIKIDO starsi (7-9 lat)<br/>
                                        Wiślicka 8
                                    </p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>17:00</td>
                                <td>
                                    <p className="aikido">
                                        17:50 - 18:50<br/>
                                        AIKIDO najstarsi (10-17 lat)<br/>
                                        Wiślicka 8
                                    </p>
                                </td>
                                <td></td>
                                <td>
                                    <p className="aikido">
                                        17:50 - 18:50<br/>
                                        AIKIDO najstarsi (10-17 lat)<br/>
                                        Wiślicka 8
                                    </p>
                                </td>
                                <td>
                                    <p className="aikido">
                                        17:00 - 17:40<br/>
                                        AIKIDO najmłodsi (4-7 lat)<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>18:00</td>
                                <td></td>
                                <td>
                                    <p className="aikido">
                                        18:00 - 18:50<br/>
                                        AIKIDO starsi (9-16 lat)<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td></td>
                                <td>
                                    <p className="aikido">
                                        17:00 - 19:00<br/>
                                        AIKIDO starsi (9-16 lat)<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td>
                                    <p className="safeteens">
                                        17:00 - 18:00<br/>
                                        Safe Teens<br/>
                                        DK Rakowiec, Wiślicka 8
                                    </p>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>19:00</td>
                                <td>
                                    <p className="aikido">
                                        19:00 - 20:00<br/>
                                        AIKIDO zaj. indywidualne<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>20:00</td>
                                <td>
                                    <p className="kravka">
                                        20:00 - 21:00<br/>
                                        Krav Maga<br/>
                                        klub Artia
                                    </p>
                                </td>
                                <td>
                                    <p className="kravka">
                                        20:00 - 21:30<br/>
                                        Krav Maga<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td>
                                    <p className="kravka">
                                        20:00 - 21:00<br/>
                                        Krav Maga<br/>
                                        klub Artia
                                    </p>
                                </td>
                                <td>
                                    <p className="kravka">
                                        20:00 - 21:30<br/>
                                        Krav Maga<br/>
                                        Kielecka 44
                                    </p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>21:00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ClassesSection;