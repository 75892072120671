import './App.css';
import { BrowserRouter as Router, Routes, Route, Outlet/*, Link*/ } from "react-router-dom";
import BottomMenu from "./components/BottomMenu";
import KravkaHome from "./pages/KravkaHome"
import AikidoHome from "./pages/AikidoHome"
import Credits from "./pages/Credits"
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Equipment from "./pages/Equipment";
import InstructorsHome from "./pages/Instructors";
import ClassesHome from "./pages/Classes";
import CampHome from "./pages/CampHome";

function App() {
  return (
      <>
          <Router>
             <Routes>
                 <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
                 <Route path={`${process.env.PUBLIC_URL}/home`} element={<KravkaHome />} />
                 <Route path={`${process.env.PUBLIC_URL}/kontakt`} element={<Contact />} />
                 <Route path={`${process.env.PUBLIC_URL}/materialy`} element={<Credits />} />
                 <Route path={`${process.env.PUBLIC_URL}/credits`} element={<Credits />} />
                 <Route path={`${process.env.PUBLIC_URL}/sprzet`} element={<Equipment />} />
                 <Route path={`${process.env.PUBLIC_URL}/kravka`} element={<KravkaHome />} />
                 <Route path={`${process.env.PUBLIC_URL}/aikido`} element={<AikidoHome />} />
                 <Route path={`${process.env.PUBLIC_URL}/obozy`} element={<CampHome />} />
                 <Route path={`${process.env.PUBLIC_URL}/instruktorzy`} element={<InstructorsHome />} />
                 <Route path={`${process.env.PUBLIC_URL}/zajecia`} element={<ClassesHome />} />
             </Routes>
              <Outlet />
              <BottomMenu />
          </Router>
      </>
  );
}
export default App;
