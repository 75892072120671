import React from "react"
import "./InstructorSection.css"
import InstructorPawel from "../instructor/InstructorPawel";
import InstructorIstvan from "./instructors/InstructorIstvan";
import InstructorMagdalenaL from "./instructors/InstructorMagdalenaL";
import InstructorMagdalenaJ from "./instructors/InstructorMagdalenaJ";
import InstructorArkadiusz from "./instructors/InstructorArkadiusz";
import InstructorSzymon from "./instructors/InstructorSzymon";
function InstructorSection() {

    let count = 1;

    function position() {
        return count++ % 2 === 1 ? "left" : "right";
    }

    return(
        <section className="container instructor-section">
            <div className="row">
                <div className="col">
                    <h1>Instruktorzy i opiekunowie</h1>
                </div>
            </div>
            <hr/>
                <InstructorPawel position={position()}/>
            <hr/>
                <InstructorIstvan position={position()}/>
            <hr/>
                <InstructorArkadiusz position={position()}/>
            <hr/>
                <InstructorMagdalenaJ position={position()}/>
            <hr/>
                <InstructorMagdalenaL position={position()}/>
            <hr/>
                <InstructorSzymon position={position()}/>
            <hr/>
        </section>
    )
}
export default InstructorSection;