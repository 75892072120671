import Instructor from "../../../controls/Instructor";

function InstructorPiotr({position}) {
    let imageSrc = "/img/instructor/instructor-piotr-karpiuk.jpg";
    let name = "Piotr Karpiuk";
    let description = "Instruktor Piotr Karpiuk od ponad 17 lat prowadzi zajęcia związane z samoobroną. Przeszkolił kilkaset osób zainteresowanych podniesieniem swoich umiejętnosci w zakresie samoobrony<br/>" +
        "Posiada uprawnienia nadane przez ministra sportu.<br/>" +
        "Na swojej drodze spotkał instruktorów z wielu krajów takich jak, Izrael ,Rosja, Finlandia, Węgry i to oni ukształtowali jego postrzeganie bezpieczeństwa osobistego.";
    let items = ["instruktor Krav Maga - 2 stopień, czarny pas Krav Maga Classic", "instruktor samoobrony dla kobiet", "instruktor obrony pałką teleskopową", "instruktor walki nożem", "instruktor strzelania"];
    return (
        <Instructor position={position} imageSrc={imageSrc} name={name} description={description} items={items} />
    )
}
export default InstructorPiotr;