import React from "react"
import "./InstructorsSection.css"
import InstructorPawel from "../instructor/InstructorPawel";
import InstructorPiotr from "../kravka/instructor/InstructorPiotr";
import InstructorGabriela from "../kravka/instructor/InstructorGabriela";
import InstructorTadeusz from "../kravka/instructor/InstructorTadeusz";
import InstructorMarcin from "../aikido/instructor/InstructorMarcin";
import InstructorPrzemyslaw from "../instructor/InstructorPrzemyslaw";
import InstructorZbigniew from "../aikido/instructor/InstructorZbigniew";
function InstructorsSection() {

    let count = 1;

    function position() {
        return count++ % 2 === 1 ? "left" : "right";
    }

    return(
        <section className="container instructor-section">
            <h1>Instruktorzy</h1>
            <hr/>
            <InstructorPawel position={position()}/>
            <hr/>
            <InstructorPrzemyslaw position={position()}/>
            <hr/>
            <InstructorMarcin position={position()}/>
            <hr/>
            <InstructorZbigniew position={position()}/>
            <hr/>
            <InstructorPiotr position={position()}/>
            <hr/>
            <InstructorGabriela position={position()}/>
            <hr/>
            <InstructorTadeusz position={position()}/>
        </section>
    )
}

export default InstructorsSection;