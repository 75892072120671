import React from "react";
import "./Contact.css"
import {JodanConfiguration as Configuration} from "../data/JodanConfiguration"
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
function Contact() {
    return(
        <>
            <Navbar title="Kontakt" />
            <TopBar title="Kontakt"/>
            <section className="container contact-section">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <h1>Kontakt</h1>
                        <h5><a href="https://maps.app.goo.gl/hfENQvMreVvNbsD76">Klub Artia</a></h5>
                        Władysława Korotyńskiego 23/7<br/>
                        02-123 Warszawa<br/>
                        <a href="https://maps.app.goo.gl/hfENQvMreVvNbsD76" target="_blank" rel="noreferrer">link >></a><br/>
                        <br/>
                        <img src="/components/contact-section/phone-call-light-48w.png" alt=""/> Telefon: {Configuration.contactPhone}<br/>
                        <br/>
                        <img src="/components/contact-section/email-mono-48w.png" alt=""/> E-mail: <a href={"mailto: " + Configuration.contactEmail}>{Configuration.contactEmail}</a><br/>
                        <br/>
                        <img src="/components/contact-section/facebook-mono-48w.png" alt=""/><a href={Configuration.jodanKravMagaFacebook} target="_blank" rel="noreferrer"> Facebook</a><br/>
                        <br/>
                        <img src="/components/contact-section/instagram-mono-48w.png" alt=""/><a href={Configuration.jodanKravMagaInstagram} target="_blank" rel="noreferrer"> Instagram</a><br/>
                    </div>
                    <div className="col-12 col-sm-6">
                        <iframe title="Klub Artia" src={Configuration.artiaGmaps} width="100%" height="100%" style={{border: "0", padding: "0.5em"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;