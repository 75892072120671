import React from "react"
import "./InstructorSection.css"
import InstructorPawel from "../instructor/InstructorPawel";
import InstructorPiotr from "./instructor/InstructorPiotr";
import InstructorGabriela from "./instructor/InstructorGabriela";
import InstructorTadeusz from "./instructor/InstructorTadeusz";
function InstructorSection() {

    let count = 1;

    function position() {
        return count++ % 2 === 1 ? "left" : "right";
    }

    return(
        <section className="container instructor-section">
            <div className="row">
                <div className="col">
                    <h1>Trenerzy</h1>
                </div>
            </div>
            <hr/>
            <InstructorPawel position={position()}/>
            <hr/>
            <InstructorPiotr position={position()}/>
            <hr/>
            <InstructorGabriela position={position()}/>
            <hr/>
            <InstructorTadeusz position={position()}/>
        </section>
    )
}

export default InstructorSection;